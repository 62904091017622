import { common } from '../common/index'
import { loginn } from '../../src/login/index'
import { publicView } from '../publicview/redux-publicView/index'

const config = {
    ...common.restApi,
    ...loginn.restApi,
    ...publicView.restApi

}

const middleware = config => store => next => action => {
    return config[action.type]
    ? next(action) &&
      store.dispatch({...action, type: `${action.type}_PENDING`}) &&
      config[action.type](store, action.payload, action.meta)
    : next(action)
}

export default {
    middleware,
    config
}