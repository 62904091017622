import { all } from 'redux-saga/effects'

import loginTask from './login/loginTask'

const taskListeners = [
    loginTask.listener()
]

export default function* rootSaga(...args){
    yield all([...taskListeners])
}