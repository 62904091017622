import React, { Component } from 'react';
import '../layout/PublicHeader.css';
import hakwalk from './../assets/logo/hakwalk.png';
import { Navbar,Nav } from "react-bootstrap";
import {Link } from 'react-router-dom'
class PublicHeader extends Component {
    render() {
        return (
            <>
            <div className="padding-fix">
                <Navbar expand="lg" className="navbar navbar-expand-md fixed-top">
                    <Navbar.Brand  href="/">
                        <img alt="hakwalk logo" src={hakwalk} className="hakwalk-logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            <Link className="btn btn-warning float-right mr-1" to="/AboutUs">About</Link>
                            <Link className="btn btn-warning float-right mr-1" to="/ContactUs">Contact</Link>
                            {/* <Link className="btn btn-warning float-right mr-1" to="/Partners">Partners</Link> */}
                            <Link className="btn btn-warning float-right mr-1" to="/Services">Services</Link>
                            <Link className="btn btn-warning float-right mr-1" to="/">Home</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            </>
        );
    }
}

export default PublicHeader;
