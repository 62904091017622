import React, { Component } from 'react';
import '../layout/Footer.css';
import faceBookLogo from './../assets/icons/facebook.jpg';
import twitterLogo from './../assets/icons/twitter.jpg';
import instagramLogo from './../assets/icons/instagram.jpg';
import hakwalkLogo from './../assets/logo/hakwalk_logo.png';
import {Link } from 'react-router-dom'
class PublicFooter extends Component {
    render() {
        return (
            <div className="public-footer">
                <hr className="hrTag"></hr>
                <div className="footer">
                    <div className="footer-container">
                        <div className="inline-flex">
                            <div className="vertical-line-public-footer">
                                <Link title="hakwalk" to="/">
                                    <img alt="hakwalk logo" src={hakwalkLogo} className="hakwalk-logo-footer" />
                                </Link>
                            </div>
                            <div className="footer-brand">HAKWALK
                            </div>
                            <div className="social-links">
                                <ul>
                                    <li>
                                        <a title="Join us on Facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Hakwalk-Inc-103920805301630">
                                            <img alt="Facbook logo" src={faceBookLogo} width="27" height="27" />
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Join us on Twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/hakwalk/">
                                            <img alt="Twitter logo" src={twitterLogo} width="27" height="27" />
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Join us on Instagram" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hakwalk/">
                                            <img alt="Instagram logo" src={instagramLogo} width="27" height="27" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="social-links-767 inline-flex">
                            <ul>
                                <li>
                                    <a title="Join us on Facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Hakwalk-Inc-103920805301630">
                                        <img alt="Facbook logo" src={faceBookLogo} width="27" height="27" />
                                    </a>
                                </li>
                                <li>
                                    <a title="Join us on Twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/hakwalk/">
                                        <img alt="Twitter logo" src={twitterLogo} width="27" height="27" />
                                    </a>
                                </li>
                                <li>
                                    <a title="Join us on Instagram" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hakwalk/">
                                        <img alt="Instagram logo" src={instagramLogo} width="27" height="27" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="float-section inline-block">
                            <nav className="" role="navigation" aria-label="Explore the site.">
                                <Link className="footer-link" to="/SignUp">SignUp</Link>
                                {/*<Link className="footer-link" to="/PublicBooks">Books</Link>*/}
                                <Link className="footer-link" to="/Login">Login</Link>
                            </nav>
                            <aside className="footer-copy-right" aria-label="The Fine Print.">
                                <span className="copy-right-text">© 2021 Hakwalk Inc.&nbsp;
                                        <Link className="legal-link" to="#">Legal.</Link>
                                </span>
                            </aside>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PublicFooter;
