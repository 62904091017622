import React, { Component } from 'react';
//import AboutHakwalk from './../assets/videos/AboutHakwalk.mp4';
import './AboutUs.css'
import PublicFooter from "../layout/PublicFooter";
import * as app from '../appSelector'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
class AboutUsPage extends Component {
  render() {
    return (
      <div>
        {/* <div>
          <video className="video-class-about" autoPlay loop>
            <source src={AboutHakwalk} type='video/mp4' />
          </video>
          <div className="mid-video-about-mission-head">
            <h5>OUR MISSION:</h5>
          </div>
   
        </div> */}
        <div className="about-durchere">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="about-vertical-spce">
                <h1 className="about-h1">MISSION HAKWALK</h1>
              </div>
            </div>
            <div className="vertical-line-about hidden-mobile">
            </div>
            <div className="about-horizonal-spce"></div>
            <div className="col-md-4">
              <div className="about-vertical-spce-mission">
                <div className="about-vertical-spce-p">
                  <p className="text-mission-about"><strong>"Hakwalk's mission is to make the best solution for every business modal"</strong></p>
                </div>
                <p>&nbsp;&nbsp;Our mission is to provide the best lucid,user friendly solution for every business modal around the world.We will ensure the prodcut is the best as you have in your mind.</p>
                <p>we want you to fall in love with our product architectured by you for your business like how you have passion towards your dream.</p>
                <p>Based in Washington, D.C.-20148</p>
              </div>
            </div>
          </div>
        </div>
{!this.props.isLoggedIn&&<PublicFooter />}      
      </div>
    );
  }
}
const s = ({ state }) => ({
  isLoggedIn: app.getLoginStatus(state),
})
const d = (d) => ({
  actions: bindActionCreators(
    {
    //  ...loginn.actions
    },
    d
  )
})
export default connect(s,d)(AboutUsPage);
