import React, { Component } from 'react';
import './Public.css';
import Carousel from 'react-bootstrap/Carousel'
import PublicFooter from "../layout/PublicFooter";
import AppDevelopment from './../assets/images/AppDevelopment.jpg';

class PublicPage extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                {/* <Carousel indicators={false} interval={null} controls={false} wrap={false} className="vert" ref={carousel => this.carousel = carousel }> */}
                {/* <div>
                      <div className="slide-1 imgclass">
                          <h2 className="midwhite">DURCHERE..!</h2>
                      </div>
                  </div> */}
                <div>
                    <div className="slide-2 imgclass">
                        <div className="slide-container">
                            <div className="slide-details">
                                <div className="slide-title">
                                    #WorkHak
                                </div>
                                <div className="slide-sub-title">
                                    <hr className="sub-title-separator" />
                                    <div>Hakwalk wants to ensure work life balance hak with providing daily Workhak software products</div>
                                    <div>Every product designed, architectured, developed, administered by ;Hakwalk are user friendly with best edge technolgies</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                        <img className="img-fluid" alt="AppDevelopment" src={AppDevelopment} />
                    </div>
                <div>
                    <div className="slide-3 imgclass">
                        <div className="slide-container font-black">
                            <div className="slide-details">
                                <div className="slide-title">
                                    #Grow
                                </div>
                                <div className="slide-sub-title">
                                    <hr className="sub-title-separator-dark" />
                                    <div>
                                        Grow Like the the flower grows from bud to flower
                                    </div>
                                    <div>
                                        we want our products and services to help you grow your business
                                    </div>
                                    <div>
                                        to cloud <i className="fas fa-cloud"></i>.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="slide-4 imgclass">
                        <div className="slide-container font-black">
                            <div className="slide-details">
                                <div className="slide-title">
                                    #Environment
                                </div>
                                <div className="slide-sub-title">
                                    <hr className="sub-title-separator-dark" />
                                    <div>
                                        As technology providers we want to ensure the safe and best future for the Gen Alpha
                                    </div>
                                    <div>
                                        Let us help the environment and prosper the future with our products.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="slide-5 imgclass">
                        <div className="slide-container">
                            <div className="slide-details">
                                <div className="slide-title">
                                    #Artificial Intelligence
                                </div>
                                <div className="slide-sub-title">
                                    <hr className="sub-title-separator" />
                                    <div>We know you want life to be easy</div>
                                    <div>from switching on lights, to going to mars</div>
                                    <div>We are making sure to reduce the human errors to achieve these goals sooner rather than later</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="slide-6 imgclass">
                        <div className="slide-container font-black">
                            <div className="slide-details">
                                <div className="slide-title">
                                    #Thank you!
                                </div>
                                <div className="slide-sub-title">
                                    <hr className="sub-title-separator" />
                                    <div>We thank each and every </div>
                                    <div>Partner, client who we partnered and collaborating to bring out the best, futurustic and environmental help software products to the world</div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PublicFooter />
                </div>
                {/* </Carousel> */}
            </div>
        );
    }
}
export default PublicPage;
