import axios from 'axios'
import APICONF from '.././config/ApiConfig.js';

import loginn from './loginn'

import loginTask from './loginTask'

export const login = (action) => async (
   { dispatch, getState },
   { username, password }
) => {
   const [err, result] = await axios
      .post(APICONF.LOGIN, {
         username,
         password
      })
      .then(res => [null, res.data])
      .catch(err => [err, null])
   const actionToDispatch = err ? action.failure(err) : action.success(result)
   if (!err) {
      const loginTime = new Date().getTime()
      window.localStorage.setItem('loginTime',loginTime/1000)
      const expiryTime = result.expTime-5
      window.localStorage.setItem('isLoggedIn', true)
      window.localStorage.setItem('expiryTime',expiryTime)
      localStorage.setItem('userData', JSON.stringify(result))
      new Promise((resolve, reject) => {
         setTimeout(() => {
            resolve(
               dispatch(loginn.actions.setRefreshTokenModalStatus(true))
            )
         },expiryTime*60000)
      })
   }
   return dispatch(actionToDispatch)
}

export const renewToken = (action) => async (
   { dispatch, getState },
   { username, jwt }
) => {
   const startAssesmentStarted = getState().state.get('startAssessment', false)
   const endAssesment =  window.localStorage.getItem('endAssesment')
   const [err, result] = await axios.get(`${APICONF.RENEW_TOKEN}/${username}`,{
      headers: {
          "authorization": jwt
      }
   })
   .then(res => [null,res.data])
   .catch( err => [err, null])
   const actionToDispatch = err ? action.failure(err) : action.success(result)
   if (!err) {
      const expiryTime = result.expTime-5
      if(startAssesmentStarted && !endAssesment){
         new Promise((resolve, reject) => {
            setTimeout(
               () => {
                  resolve(
                     dispatch(loginn.actions.renewToken({
                        username,
                        jwt
                     }))
                  )
               },
               expiryTime*60000
               // 7000

            )
         })

      }else{
         new Promise((resolve, reject) => {
            setTimeout(() => {
               resolve(
                  dispatch(loginn.actions.setRefreshTokenModalStatus(true))
               )
            },expiryTime*60000)
         })

      }
      
   }
   return dispatch(loginn.actions.setRefreshTokenModalStatus(false)) && dispatch(actionToDispatch) 
}


export const signup = (action) => async ({ dispatch }, {
   firstName,
   lastName,
   username,
   email,
   passWord,
   phoneNumber,
   history
}) => {
   await axios
      .post(APICONF.SIGNUP, {
         firstName,
         lastName,
         username,
         email,
         passWord,
         phoneNumber,
      })
      .then(res => {
         if ([500].includes(res.data.status)) {
            // throw new Error(res.responseMessages[0].longMessage);
            return dispatch(action.failure(new Error(res.data.responseMessages[0].longMessage)))
         }else{
            history.push('/Login')
         }
      return dispatch(action.success(res)) 

   })
   .catch(err => dispatch(action.failure(err)))
      
}

export const userNameSubmit = action => async ({ dispatch }, username) => {
   const [err, result] = await axios
      .get(`${APICONF.FORGOT_PASSWORD}/${username}`)
      .then(res => [null, res.data])
      .catch(err => [err, null])

   let actionToDispatch;
   if(err){
      actionToDispatch = action.failure(err);
   }
   else if (result.status === 'otp email sent') {
      actionToDispatch = action.success(result);
   } else {
      // actionToDispatch = action.success(result);
      actionToDispatch = action.failure(err);
   }
   return dispatch(actionToDispatch)
}

export const otpSubmit = action => async ({ dispatch }, { username, otp }) => {
   const [err, result] = await axios
      .get(`${APICONF.VALIDATE_OTP}/${username}/${encodeURIComponent(otp)}`)
      .then(res => [null, res.data])
      .catch(err => [err, null])

   let actionToDispatch;
   if(err){
      actionToDispatch = action.failure(err);
   }
   else if (result === 'Valid otp') {
      actionToDispatch = action.success(result);
   } else {
      actionToDispatch = action.failure(err);
   }
   return dispatch(actionToDispatch)
}

export const changePasswordSubmit = action => async ({ dispatch }, {
   username,
   otp,
   password,
   history
}) => {
   const [err, result] = await axios
      .post(`${APICONF.UPDATE_PASSWORD}`, {
         username,
         otp,
         password
      })
      .then(res => [null, res.data])
      .catch(err => [err, null])

   let actionToDispatch;
   if(err){
      actionToDispatch = action.failure(err);
   }
   else if (result.status === 'Password updated successfully') {
      new Promise( (resolve, reject) => {
         setTimeout(
            resolve(history.push('/Login')),
            1500
         )
      })
      actionToDispatch = action.success(result);

   }
   else {
      actionToDispatch = action.failure(err);
   }
   return dispatch(actionToDispatch)
}

export const emailSubmit = action => async ({ dispatch }, emailId) => {

   const [err, result] = await axios
      .get(`${APICONF.FORGOT_USERNAME}/${emailId}`)
      .then(res => [null, res.data])
      .catch(err => [err, null])

   let actionToDispatch;
   if(err){
      actionToDispatch = action.failure(err);
   }
   else if (result.status === 'Success') {
      actionToDispatch = action.success(result);
   } else {
      actionToDispatch = action.failure(result.status);
   }
   return dispatch(actionToDispatch)
}