import React, {Component} from 'react';
import PublicFooter from "../layout/PublicFooter";

class CareersPage extends Component {
  render() {
    return (
        <div>
          <div className="container">
            Test Careers
          </div>
        </div>
    );
  }
}
export default CareersPage;
