
export const getUserNameErrorMessage =  state => {
    return state.get('userNameErrorMsg','')
}

export const getSignupErrorMessage = state => {
    return state.get('signupError');
}

export const getForgotPassword = state => {
    return state.get('forgotPassword');
}

export const getForgotUsername = state => {
    return state.get('forgotUsername');
}

export const getLoggedInStatus = state => {
    return state.get('isLoggedIn', false)
}