import { createReducer } from 'redux-act'
import { Map } from 'immutable'
import { common } from './common/index'
import { loginn } from './login/index'
import { publicView } from './publicview/redux-publicView/index'



const initState = Map()

const reducers = {
  ...common.reducer,
  ...loginn.reducer,
  ...publicView.reducer,
}

export default createReducer(reducers,initState)