import React, {Component} from 'react';
import './Public.css';
import Carousel from 'react-bootstrap/Carousel'
import PublicFooter from "../layout/PublicFooter";

class Services extends Component {

    constructor(props) {
        super(props);
    }
      render() {
        return (
          <div>
                  <div>
                      <div className="slide-1-services imgclass">
                          <div className="slide-container">
                              <div className="slide-details">
                              </div>
                          </div>
                      </div>
                  </div>
                  <div>
                      <div className="slide-2-services imgclass">
                          <div className="slide-container font-black">
                              <div className="slide-details">
                              </div>
                          </div>
                      </div>
                  </div>
                   <div>
                      <div className="slide-3-services imgclass">
                          <div className="slide-container font-black">
                              <div className="slide-details">
                              </div>
                          </div>
                      </div>
                  </div>
                  <div>
                      <div className="slide-4-services imgclass">
                          <div className="slide-container">
                              <div className="slide-details">
                              </div>
                          </div>
                      </div>
                  </div>
                  <div>
                      <PublicFooter/>
                  </div> 
          </div>
        );
      }
}
export default Services;
