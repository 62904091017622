import React, { Component } from 'react';

class AlertBox extends Component {
    render() {
        var successAlert = this.props.successAlert;
        var errorAlert = this.props.errorAlert;
        var successMsg = this.props.successMsg;
        var errorMsg = this.props.errorMsg;
        return (
            <div>
                {successAlert ? (<div className="alert alert-success" role="alert">
                    {successMsg}
                </div>) : (errorAlert) ? (<div className="alert alert-danger" role="alert">
                    {errorMsg}
                </div>) : null
                }
            </div>
        );
    }
}
export default AlertBox;