import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import PublicHeader from '../layout/PublicHeader';
import { createBrowserHistory } from 'history';
import Login from '../login/Login.js';
import Signup from '../login/Signup.js';
import PublicPage from '../publicview/PublicPage.js';
import ForgotPassword from '../login/ForgotPassword';
import ForgotUsername from '../login/ForgotUsername';
import PartnersPage from '../publicview/PartnersPage';
import NotFoundPage from '../common/404'
import Services from '../publicview/Services';


export default class PublicRouter extends Component {
  render() {
    return (
      <Router history={createBrowserHistory}>
        <div>
        <PublicHeader></PublicHeader>
          <Switch>
          <Route exact path="/Logout">
              <Redirect to="/Login"/>
          </Route>
            <Route exact path="/" component={PublicPage} />
            <Route exact path="/Login" component={Login} />
            <Route exact path="/Signup" component={Signup} />
            <Route exact path="/partners" component={PartnersPage} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/forgot-username" component={ForgotUsername} />
            <Route exact path="/Services" component={Services} />
            {this.props.commonRoutes()}
            {!this.props.loggedIn &&
              <Route  exact path="/">
                <Redirect to="/Login" push />
              </Route>
            }
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </Router>
    )
  }
}
