import React, { Component } from 'react';
import PublicRouter from '../src/routers/PublicRouter.js';
import { Route } from 'react-router-dom'
import PartnersPage from '../src/publicview/PartnersPage';
import AboutUsPage from '../src/publicview/AboutUsPage.js';
import CareersPage from '../src/publicview/CareersPage.js';
import ContactUsPage from '../src/publicview/ContactUsPage.js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as app from './appSelector'
import { loginn } from './login/index'
import loginTask from './login/loginTask'
import './App.css'
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      isLoggedIn: false,
      userData: null,

    };
    this.commonRoutes = this.commonRoutes.bind(this);
  }
  componentDidMount() {
    var loggedIn = localStorage.getItem('isLoggedIn')
    if (!this.props.isLoggedIn && loggedIn) {
      this.props.actions.setDataAfterReload({
        isLoggedIn: localStorage.getItem('isLoggedIn'),
        userData: localStorage.getItem('userData'),
        refreshTokenModal: window.localStorage.getItem('refreshTokenModal')
      })
    }
  }

  commonRoutes() {
    return (
      <React.Fragment>
        <Route exact path="/Partners" component={PartnersPage} />
        <Route eaxct path="/AboutUs" component={AboutUsPage} />
        <Route exact path="/Careers" component={CareersPage} />
        <Route exact path="/ContactUs" component={ContactUsPage} />
        {/* <Route component={NotFoundPage} /> */}
      </React.Fragment>
    )
  }



  render() {
    return (
      <div>
        {!this.props.isLoggedIn && (
          <PublicRouter
            loggedIn={this.props.isLoggedIn}
            commonRoutes={this.commonRoutes}
          />
        )}
      </div>
    );
  }
}

const s = ({ state }) => ({
  isLoggedIn: app.getLoginStatus(state),
  userData: app.getUserData(state),
  refreshToken:app.getRefreshTokenModalStatus(state),
  startAssessment: app.startAssessment(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...loginn.actions,
      ...loginTask.actions
    },
    d
  )
})
export default connect(s, d)(App);

