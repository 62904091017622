const API_MSGS = {

    ADD_USER_MSG_SUCCESS:'Successfully added the user to Organization',
    ADD_USER_MSG_FAIL:'Failed to add the user to Organization',
    EDIT_USER_MSG_SUCCESS:'Successfully updated the user to Organization',
    EDIT_USER_MSG_FAIL:'Failed to update the user to Organization',
    ADD_GROUP_MSG_SUCCESS:'Successfully created the group',
    ADD_GROUP_MSG_FAIL:'Failed to create the group',
    ASSIGN_COURSES_MSG_SUCCESS:'Successfully Assigned the course to the user',
    ASSIGN_COURSES_MSG_FAIL:'Failed to Assign the course to the user',
    ASSIGN_GROUPS_MSG_SUCCESS:'Successfully Assigned the groups to the user',
    ASSIGN_GROUPS_MSG_FAIL:'Failed to Assign the groups to the user',
    REMOVE_USER_MSG_SUCCESS: 'Successfully Deleted the the user from Association',
    REMOVE_USER_MSG_FAIL: 'Failed to Delete the the user from Association',
    ADD_BOOKS_SUCCESS:'Successfully added the Books Details to Organization',
    ADD_BOOKS_FAIL:'Failed to add the Books Details to Organization',
    DELETE_BOOK_SUCCESS:'Successfully Deleted the Book Details from Organization',
    DELETE_BOOK_FAIL:'Failed to delete the Book Details from Organization',
    CONTACT_FORM_SUCCESS:'We will reach you as soon as possible',
    CONTACT_FORM_FAIL:'Please Fill All The Details to Reach us',
    COURSE_DELETE_SUCCESS: 'Course deleted Successfully',
    COURSE_DELETE_FAILURE: 'Course  is not deleted successfully',
    STANDARD_DELETE_SUCCESS: 'Successfully deleted standard',
    STANDARD_DELETE_FAILURE: 'Standard is not deleted successfully'
}
export default API_MSGS