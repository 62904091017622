import { createAction } from 'redux-act'
import {createAsyncAction} from '../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const login = createAsyncAction(
    `LOGIN`,
    payload => payload
)

const signup = createAsyncAction(
  `SIGNUP`,
  payload => payload
)

const usernameSubmit = createAsyncAction(
  `USERNAME_SUBMIT`,
  payload => payload
)

const otpSubmit = createAsyncAction(
  `OTP_SUBMIT`,
  payload => payload
)

const changePasswordSubmit = createAsyncAction(
  `CHANGE_PASSWORD_SUBMIT`,
  payload => payload
)

const emailSubmit = createAsyncAction(
  `EMAIL_SUBMIT`,
  payload => payload
)

const setDataAfterReload = createAction(
    'SET_DATA_AFTER_RELOAD',
    payload => payload
)

const setSignUpSuccessMsg = createAction(
  'SET_SIGNUP_MESSAGE',
  payload => payload
)

const renewToken = createAsyncAction(
  `RE_NEW_TOKEN`,
  payload => payload
)

const setRefreshTokenModalStatus = createAction(
  `REFRESH_MODAL_STATUS`,
  payload => payload
)

const removeUserData = createAction(
  `REMOVE_USER_DATA`,
  payload => payload
)

const reducer = {
    [login.success]: (state, payload) =>
      mutators.setLoginStatus(payload)(state),
    [renewToken.success]: (state, payload) => 
     mutators.setNewJwt(payload)(state),
    [login.failure]: (state,payload)=>
      mutators.setUserNameErrorMessage(payload)(state),
    [signup.success]: (state,payload)=>
      mutators.setSignUpSuccessMsg(payload)(state),
    [signup.failure]: (state,payload)=>
      mutators.setSignupErrorMessage(payload)(state),
    [usernameSubmit.success]: (state,payload)=>
      mutators.setUsernameSubmitSuccessMsg(payload)(state),
    [usernameSubmit.failure]: (state,payload)=>
      mutators.setUsernameSubmitErrorMsg(payload)(state),
    [otpSubmit.success]: (state,payload)=>
      mutators.setOTPSubmitSuccessMsg(payload)(state),
    [otpSubmit.failure]: (state,payload)=>
      mutators.setOTPSubmitErrorMsg(payload)(state),
    [changePasswordSubmit.success]: (state,payload)=>
      mutators.setChangePasswordSubmitSuccessMsg(payload)(state),
    [changePasswordSubmit.failure]: (state,payload)=>
      mutators.setChangePasswordSubmitErrorMsg(payload)(state),
    [emailSubmit.success]: (state,payload)=>
      mutators.setEmailSubmitSuccessMsg(payload)(state),
    [emailSubmit.failure]: (state,payload)=>
      mutators.setEmailSubmitErrorMsg(payload)(state),
    [setDataAfterReload]: (state, payload) =>
      mutators.setDataAfterReload(payload)(state),
    [setSignUpSuccessMsg]: (state, payload) =>
      mutators.setSignUpSuccessMsg(payload)(state),
    [setRefreshTokenModalStatus]: (state, payload) =>
      mutators.setRefreshTokenModalStatus(payload)(state),
    [removeUserData]:(state, payload) =>
      mutators.removeUserData(payload)(state)
}

const restApi = {
   [login]: api.login(login),
   [renewToken]: api.renewToken(renewToken),
   [signup]: api.signup(signup),
   [usernameSubmit]: api.userNameSubmit(usernameSubmit),
   [otpSubmit]: api.otpSubmit(otpSubmit),
   [changePasswordSubmit]: api.changePasswordSubmit(changePasswordSubmit),
   [emailSubmit]: api.emailSubmit(emailSubmit)
}

export default {
    actions: {
        login,
        signup,
        setDataAfterReload,
        setSignUpSuccessMsg,
        usernameSubmit,
        otpSubmit,
        changePasswordSubmit,
        emailSubmit,
        renewToken,
        setRefreshTokenModalStatus,
        removeUserData
    },
    restApi,
    reducer
}