import {Map} from 'immutable'

export const getLoginStatus = (state) => state.get('isLoggedIn',false)

export const getUserData = (state) => state.get('userData',Map())

export const getRefreshTokenModalStatus = state => state.get('refreshTokenModal', false)

export const startAssessment = state => state.get('startAssessment', false)

