// import { createAction } from 'redux-act'
import {createAsyncAction} from '../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const getDefaultGroupNames = createAsyncAction(
    `GET_DEFAULT_GROUP_NAMES`,
    payload => payload
)

const reducer = {
    [getDefaultGroupNames.success]: (state, payload) =>
      mutators.getDefaultGroupNames(payload)(state)
}

const restApi = {
   [getDefaultGroupNames]: api.getDefaultGroupNames(getDefaultGroupNames) 
}

export default {
    actions: {
        getDefaultGroupNames
    },
    restApi,
    reducer
}