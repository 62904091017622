import { createAction } from 'redux-act'
import {createAsyncAction} from '../../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const getPublicBooks = createAsyncAction(
    `GET_PUBLIC_BOOKS`,
    payload => payload
)

const submitContactForm = createAsyncAction(
    `SUBMIT_CONTACT_FORM`,
    payload => payload
)

const reducer = {
    [getPublicBooks.success]: (state, payload) =>
      mutators.getPublicBooks(payload)(state),
    [submitContactForm.success]: (state, payload) =>
      mutators.contactFormSuccessAlertBox(payload)(state),
    [submitContactForm.failure]: (state, payload) =>
      mutators.contactFormFailureAlertBox(payload)(state)
}

const restApi = {
   [getPublicBooks]: api.getPublicBooks(getPublicBooks),
   [submitContactForm]: api.submitContactForm(submitContactForm),
   
}

export default {
    actions: {
        getPublicBooks,
        submitContactForm
    },
    restApi,
    reducer
}